import React from "react"

import Layout from "../components/layout"
import SEO from "../components/seo"

const ServicePage = ({ data }) => {
  return (
    <Layout header="nomenu">
      <div className="no-masthead relative"></div>

      <section className="section sectionFirst">
        <div className="container py-10">
          <iframe
            className="w-full h-[2000px] md:h-[1800px]"
            src="https://service-appointment.bmw.com.sg/form/eyJpdiI6InlNTGJCNExSVUk1c3I5aHhU"
            border="0"
            frameborder="0"
          ></iframe>
        </div>
      </section>
      <script
        dangerouslySetInnerHTML={{
          __html: `window._adftrack = Array.isArray(window._adftrack) ? window._adftrack : (window._adftrack ? [window._adftrack] : []);
        window._adftrack.push({
            HttpHost: 'asia.adform.net',
            pm: 1824002,
            divider: encodeURIComponent('|'),
            pagename: encodeURIComponent('EA Service Booking Landing Page')
        });
        (function () { var s = document.createElement('script'); s.type = 'text/javascript'; s.async = true; s.src = 'https://s2.adform.net/banners/scripts/st/trackpoint-async.js'; var x = document.getElementsByTagName('script')[0]; x.parentNode.insertBefore(s, x); })();`,
        }}
      ></script>
      <noscript>
        <p className="border-0 p-0 m-0">
          <img
            src="https://asia.adform.net/Serving/TrackPoint/?pm=1824002&ADFPageName=EA%20Service%20Booking%20Landing%20Page&ADFdivider=|"
            width="1"
            height="1"
            alt=""
          />
        </p>
      </noscript>
      <script
        dangerouslySetInnerHTML={{
          __html: `
!function(f,b,e,v,n,t,s)
{if(f.fbq)return;n=f.fbq=function(){n.callMethod?
n.callMethod.apply(n,arguments):n.queue.push(arguments)};
if(!f._fbq)f._fbq=n;n.push=n;n.loaded=!0;n.version='2.0';
n.queue=[];t=b.createElement(e);t.async=!0;
t.src=v;s=b.getElementsByTagName(e)[0];
s.parentNode.insertBefore(t,s)}(window, document,'script',
'https://connect.facebook.net/en_US/fbevents.js');
fbq('init', '608633623033362');
fbq('track', 'PageView');`,
        }}
      ></script>
      <noscript>
        <img
          height="1"
          width="1"
          className="hidden"
          src="https://www.facebook.com/tr?id=608633623033362&ev=PageView&noscript=1"
        />
      </noscript>
    </Layout>
  )
}

export default ServicePage

export const Head = () => (
  <>
    <script
      async
      src="https://www.googletagmanager.com/gtag/js?id=AW-782099393"
    ></script>
    <script
      async
      src="https://www.googletagmanager.com/gtag/js?id=G-8FJZCNFV3R"
    ></script>
    <script
      async
      src="https://www.googletagmanager.com/gtag/js?id=GTM-M7DKTD9"
    ></script>
  </>
)
